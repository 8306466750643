/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import home_image from '../images/Cape-Town-2-crop.jpg'
import expertise_image from '../images/expertise-header-crop.jpg'
import about_image from '../images/about-us-crop.jpg'
import news_image from '../images/news-crop.jpg'
import contact_image from '../images/contact-us-crop.jpg'
import terms_image from '../images/TCs-crop.jpg'

export  function PageCoverHeader(props) {
    const { page, title } = props
    let image = home_image
    
    if (page === "expertise") {
        image = expertise_image
    } else if (page === "about") {
        image = about_image
    } else if (page === "news") {
        image = news_image
    } else if (page === "contact") {
        image = contact_image
    } else if (page === "terms") {
        image = terms_image
    }
    
    return (
        <div css={ wrapper(image) }>
          <div css={ header_text(page === "contact" || page === "terms" ? "black" : "white" ) }>
            { title }
          </div>
        </div>
    )
}

const wrapper = (image) => css`
    max-width: 100%;
    max-height: 450px;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(95%);
`

const header_text = (color) => css`
    font-size: 2.0vw;
    font-weight: 600;
    font-family: "Lato", serif;
    color: ${color};
    text-align: center;
    padding-top: 6vw;
    padding-left: 25vw;
    padding-right: 25vw;
    padding-bottom: 9.5vw;
    line-height: 3.4vw;
`
