import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom"

import { UIGlobalLayout } from './ui/index'

import './index.css';
import AppRoutes from './AppRoutes';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
function App() {
    return (
        <BrowserRouter>
          <UIGlobalLayout>
            <AppRoutes />
          </UIGlobalLayout>
        </BrowserRouter>
    )
}
root.render(
//    <React.StrictMode>
        <App />
    //{/* </React.StrictMode> */}
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
