/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from "react"
import Slider from "react-slick"


//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";

import nedbank_logo from '../images/Nedbank-logo.jpg'
import investec_logo from '../images/investec-logo.png'
import sahl_logo from '../images/SA-home-loans-logo.jpg'
import old_mutual_logo from '../images/old-mutual-logo.jpeg'
import jlm_logo from '../images/jlm-property-logo.png'
import intasure_logo from '../images/Intasure-logo.png'
import pohl_logo from '../images/pands-logo.png'
import sa_gov_logo from '../images/SA-Government-logo.png'
import nedbank_private_logo from '../images/nedbank-private-wealth-logo.jpg'
import spearhead_logo from '../images/Spearhead-logo.png'
import sygnia_logo from '../images/sygnia-logo.png'
import waldorf_logo from '../images/waldorf-constantia-logo.png'

export function BusinessAffiliates(props) {
    const { heading, heading_style } = props
    
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 2,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 3000,
        centerPadding: '2.5vw',
    }
    
    return (
        <div css={ content_wrapper }>
          <h2 css={ heading_style === "homepage" ? section_heading_home : section_heading_expertise }>
            { heading }
          </h2>
          <div css={ slider_wrapper }>
            <Slider {...settings}>
              <div css={icon_css(nedbank_logo)}></div>
              <div css={icon_css(investec_logo)}></div>
              <div css={icon_css(sahl_logo)}></div>
              <div css={icon_css(old_mutual_logo)}></div>
              <div css={icon_css(jlm_logo)}></div>
              <div css={icon_css(intasure_logo)}></div>
              <div css={icon_css(pohl_logo)}></div>
              <div css={icon_css(sa_gov_logo)}></div>
              <div css={icon_css(nedbank_private_logo)}></div>
              <div css={icon_css(spearhead_logo)}></div>
              <div css={icon_css(sygnia_logo)}></div>
              <div css={icon_css(waldorf_logo)}></div>
            </Slider>
          </div>
        </div>
    )
}

const content_wrapper = css`
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-top: 1px solid #d2d2d4;
    min-height: 7.5vw;
    height: 15.5vw;
    padding-bottom: 1.6vw;
    .slick-arrow {
    background-color: #d2d2d4;
    border-radius: 0.5vw;
    }
`

const section_heading_home = css`
    font-family: "Play-fair Display", serif;
    font-size: 2.25vw;
    letter-spacing: 0.06vw;
    color: #595858;
    text-align: center;
    font-weight: 600;
`

const section_heading_expertise = css`
    font-family: "Roboto", sans-serif;
    font-size: 2.525vw;
    letter-spacing: 0.1vw;
    color: #222329;
    text-align: center;
    font-weight: 900;
`

const slider_wrapper = css`
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
`

const icon_css = (image) => css`
    height: 7.5vw;
    width: 25%;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-size: 7vw;
`

