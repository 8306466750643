/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useNavigate } from "react-router-dom"
import { UIButton } from '../ui/UIButton'

import home_expertise from '../images/home-expertise.jpg'

export function ExpertiseShort(props) {
    const navigate = useNavigate()

    function onClickFn() {
        navigate("/expertise")
    }

    return (
        <table css={ content_wrapper }>
          <tbody>
          <tr css={ row_css }>
            <td css={ split_pane_left }>
              <h3 css={ heading_css }>Our Expertise</h3>
              <p css={ text_css }>
                Our valuers are experienced in a broad range of property valuations – ranging from residential, commercial and industrial properties to the more complex specialised properties.
              </p>
              <div css={ button_section }>
                <UIButton
                    color="white"
                    width="6vw"
                    height="2vw"
                    background_color="#6c7574"
                    label="Read more"
                    onClickFn={ onClickFn }
                />
              </div>
            </td>
            <td css={ split_pane_right }>
              
            </td>
          </tr>
          </tbody>
        </table>
    )
}

const content_wrapper = css`
    width: 100%;
    height: 27vw;
    border: none;
    border-collapse: collapse;
`

const split_pane_left = css`
    background-color: #222329;
    height: 27vw;
    width: 50%;
    padding-left: 2.4vw;
    padding-right: 2.4vw;
`

const split_pane_right = css`
    background-image: url(${home_expertise});
    background-repeat: no-repeat;
    background-size: cover;
    height: 27vw;
    width: 50%;
`

const heading_css = css`
    color: white;
    font-family: "Playfair Display", Serif;
    font-size: 2.25vw;
    font-weight: 600;
`

const row_css = css`

`

const text_css = css`
    color: #6c7574;
    font-size: 0.8vw;
    font-family: "Roboto", sans-serif;
    font-weight: 100;

`

const button_section = css`
    display: block;
    margin-top: 0.8vw;
    padding-top: 1.51vw;
    width: 100%;
    min-height: 2vw;
`
