/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

export function TeamMember(props) {
    const { name, image, extra_info, qualifications } = props

    return(
        <div css={ member_card_wrapper }>
          <div css={ member_image(image) }></div>
          <div css={ member_name_plate }>
            <div css={ name_text }>
              {name}
            </div>
          </div>
          { (qualifications || extra_info) &&
            <span className="member-info" css={ member_info_css }>
              <div css={ extra_info_name }>{ name }</div>
              <div css={ extra_info_qualifications }>{ qualifications }</div>
              <div>
                {extra_info.map((info, index) => 
                    <p key={index} css={ extra_info_text }>{ info }</p>)}
              </div>
            </span>
          }
        </div>
        )
}

const member_card_wrapper = css`
    height: 13.5vw;
    width: 20vw;
    cursor: pointer;

    &:hover {

    span {
    background-color: black;
    opacity: 0.9;
    box-shadow: 0 0.2vw 0.2vw 0 rgba(0, 0, 0, 0.24), 0 0 0.2vw 0 rgba(0, 0, 0, 0.12);
    display: block;
    padding: 1.2vw;
    position: absolute;
    left: 20vw;
    right: 20vw;
    width: 60vw;
    z-index: 150;
    color: white;
    text-align: left;
    }
`

const member_image = (image) => css`
    height: 13.5vw;
    width: 20vw;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-size: cover;
`

const member_name_plate = css`
    margin-top: -1.6vw;
    background-color: #222329;
    width: 17vw;
    max-width: 14vw;
    margin-left: 2vw;
    margin-right: 2vw;
    cursor: pointer;
`

const name_text = css`
    padding: 0.4vw;
    color: white;
    text-align: center;
    font-size: 1.1vw;
    font-weight: bold;
    letter-spacing: 0.06vw;
    font-family: "Playfair Display", serif;
`

const member_info_css = css`
    display: none;
    font-family: "Roboto", sans-serif;
    color: #ffffff;
    padding: 0.8vw;
    max-width: 57vw;
`

const extra_info_name = css`
    font-weight: 400;
    font-size: 1.515vw;
    line-height: 2.52vw;
    padding-bottom: 1.2vw;
`

const extra_info_qualifications = css`
    font-weight: bold;
`

const extra_info_text = css`
    padding-top: 1.2vw;
`
