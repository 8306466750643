/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"

function Footer(props) {
    const navigate = useNavigate()
    
    return (
        <div css={ main_footer_wrapper }>
          <div css={ footer_section }>            
            <div css={ footer_link } onClick={() => navigate("/") }>Home</div>
            <div css={ footer_link } onClick={() => navigate("/expertise") }>Expertise</div>
            <div css={ footer_link } onClick={() => navigate("/about") }>About us</div>
            <div css={ footer_link } onClick={() => navigate("/news") }>News</div>
            <div css={ footer_link } onClick={() => navigate("/contact") }>Contact us</div>
          </div>
          <div css={ footer_section }>
            <div css={ contact_detail }>
              <div css={ location_icon }>
                <i className="material-icons" css={ icon_css }>location_on</i>
              </div>
              <div css={ location_text }>Southern Suburbs, Cape Town</div>
            </div>
            <div css={ contact_detail }>
              <div css={ email_icon }>
                <i className="material-icons" css={ icon_css }>mail</i>
              </div>
              <div css={ email_text }>vals@douglasproperty.co.za</div>
            </div>
            <div css={ contact_detail }>
              <div css={ phone_icon }>
                <i className="material-icons" css={ icon_css }>call</i>
              </div>
              <div css={ phone_text }>065 511 3377</div>
            </div>
          </div>
          <div css={ footer_section }>
            <div css={ quote_text }>Contact us for a quote</div>
            <div css={ follow_text }>Follow us on:</div>
            <div css={ social_media_icon_section }>
              <a css={social_media_link} href="https://www.facebook.com/dpvaluations" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFacebookSquare} css={social_media_icon} color="#3b5998" />
              </a>
              <a css={social_media_link} href="https://www.linkedin.com/company/douglas-property-valuations" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faLinkedin} css={social_media_icon} color="#0e76a8" />
              </a>
            </div>
          </div>
        </div>
    )
}

export default Footer

const main_footer_wrapper = css`
    display: block;
    margin-left: 7.5vw;
    margin-right: 7.5vw;
    padding-top: 2.5vw;
    min-height: 11.3vw;
    width: 90%;
`

const footer_section = css`
    display: inline-block;
    width: 29%;
`

const footer_link = css`
    height: 1.9vw;
    color: white;
    font-family: "Roboto", Sans-serif;
    font-size: 0.7vw;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 0.1vw;
    padding-left: 0.8vw;
    &:hover {
    cursor: pointer;
    }
`

const location_icon = css`
    display: inline-block;
    padding-right: 0.8vw;
`

const location_text = css`
    display: inline-block;
    line-height: 3.4vw;
    vertical-align: super;
    &:hover {
    color: #a3a3a3;
    }
`

const email_icon = css`
    display: inline-block;
    padding-right: 0.8vw;
`

const email_text = css`
    display: inline-block;
    line-height: 3.4vw;
    vertical-align: super;
    &:hover {
    color: #a3a3a3;
    }
`

const phone_icon = css`
    display: inline-block;
    padding-right: 0.8vw;
`

const phone_text = css`
    display: inline-block;
    line-height: 3.4vw;
    vertical-align: super;
    &:hover {
    color: #a3a3a3;
    }
`

const contact_detail = css`
    color: white;
    font-family: "Roboto", Sans-serif;
    font-size: 0.7vw;
    font-weight: 400;
    padding-left: 0.8vw;
`

const icon_css = css`
    height: 0.8vw;
    width: 0.8vw;
    font-size: 1.2vw;
`

const quote_text = css`
    line-height: 3.2vw;
    color: white;
    font-family: "Roboto", Sans-serif;
    font-size: 1.5vw;
    padding-left: 0.8vw;
`

const follow_text = css`
    line-height: 3.2vw;
    color: white;
    font-family: "Roboto", Sans-serif;
    font-size: 1vw;
    padding-left: 0.8vw;
`

const social_media_icon_section = css`
    height: 3.2vw;
`

const social_media_icon = css`
    font-size: 2.5vw;
`

const social_media_link = css`
    margin-left: 0.8vw;
    display: inline-block;
`
