/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

export function ExpertiseCard(props) {
    const { image, title, description } = props

    return (
        <div css={ expertise_card }>
          <div css={ header_image(image) }></div>
          <div css={ heading }>{title}</div>
          <div css={ divider }></div>
          <div css={ card_description }>{ description }</div>
        </div>
    )
}

const expertise_card = css`
    width: 17vw;
    min-height: 38.8vw;
    margin-left: 0.8vw;
    margin-right: 0.8vw;
    background-color: #343a40;
    color: #6c757d;

`

const header_image = (image) => css`
    width: 100%;
    height: 14.15vw;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-size: 17vw;
`

const heading = css`
    color: #ffffff;
    font-size: 1.05vw;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1vw;
    font-family: "Roboto", sans-serif;
    text-align: center;
    padding-top: 2.4vw;
    padding-left: 1.2vw;
    padding-right: 1.2vw;
    padding-bottom: 2.4vw;
`

const divider = css`
    width: 2.2vw;
    height: 0.2vw;
    margin-left: auto;
    margin-right: auto;
    background-color: #dfaa0a;
`

const card_description = css`
    margin-bottom: 1,2vw;
    text-align: center;
    font-family: "Roboto", Sans-serif;
    font-size: 0.8vw;
    font-weight: 300;
    letter-spacing: 0.005vw;
    padding-top: 2.4vw;
    padding-left: 1.2vw;
    padding-right: 1.2vw;
    color: lightgrey;
`
