/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { PageCoverHeader } from './PageCoverHeader'
import { ExpertiseCard } from './ExpertiseCard'
import { ExpertisePanels } from './ExpertisePanels'
import { BusinessAffiliates } from './BusinessAffiliates'
import ExpertiseGuarantee from './ExpertiseGuarantee'

import residential_image from '../images/residential-pic-600x500.jpg'
import commercial_image from '../images/Commercial.jpg'
import vacant_image from '../images/vacant-land-2.jpg'
import rental_image from '../images/Rent.jpg'
import insurance_image from '../images/insurance.jpg'
import development_image from '../images/Research.jpg'
import agricultural_image from '../images/Farm-600x500.jpg'
import servitude_image from '../images/Servitudes.jpg'
import antennae_image from '../images/Antennae-1.jpg'
import restitution_image from '../images/Land-R.jpg'
import specialised_image from '../images/specialist-prop-1.jpg'
import rates_image from '../images/Cape-Town-1.jpg'

function Expertise(props) {
    const residential_description = "We have a leading team of residential valuers who have over 30 years’ experience valuing in the suburbs of Cape Town and surrounding towns. We are on the board of valuers for a number banks who specialise in home loans. We can value any residential property, whether freehold, sectional title, share blocks, or retirement properties."

    const commercial_description = "Douglas Property Valuations also specialises in commercial and industrial valuations, with over 35 years’ experience in the field. We are on the board of valuers for leading business banks, such as Nedbank, ABSA, and Nedbank Private Wealth."

    const vacant_description = "Our experienced team are more than capable of determining vacant land values, or residual land values."

    const rental_description = "Our firm can provide you with rental determinations for any property – whether it is residential, commercial or industrial."

    const insurance_description = "Douglas Property Valuations has a strong team of insurance and replacement cost valuers. Our knowledge of building and construction also means we have experience in property surveys."

    const development_description = "Our founder, Colin Douglas, comes from a background in building and construction, and has years of experience with development feasibility studies. Whether the development is a small block of flats or entire satellite town, our firm has the expertise you are looking for."

    const agricultural_description = "Our founders actually own a smallholding themselves, and have a strong understanding of the valuation of these kind of properties."

    const servitude_description = "Our team is more than capable of determining valuations of a variety of different servitudes (such as height restriction, right-of-way and pipe & power lines). "

    const antennae_description = "Our team has had years of experience valuing antennas and signage on a variety of different properties."

    const restitution_description = "Our firm has experience in these lengthy, complicated valuations."

    const specialised_description = "A key advantage of our firm is that we have the skills, experience and know-how to value many specialised properties. These include service stations, hotels, cold storage, shopping malls and schools."

    const rates_description = "We are highly experienced in preparing valuation reports for the purpose of rates objections – whether residential, commercial, industrial, specialised or vacant land."
    
    return (
        <div css={ main_css }>
          <PageCoverHeader
              page="expertise"
              title="Our Expertise"
          />
          <div css={ expertise_container }>
            <div css={ row_css }>
              <div css={ expertise_grid_container }>
                <ExpertiseCard
                    title="Residential"
                    description={ residential_description }
                    image={ residential_image }
                />
                <ExpertiseCard
                    title="Commercial & Industrial"
                    description={ commercial_description }
                    image={ commercial_image }
                />
                <ExpertiseCard
                    title="Vacant land"
                    description={ vacant_description }
                    image={ vacant_image }
                />
              </div>
            </div>
            <div css={ row_css }>
              <div css={ expertise_grid_container }>
                <ExpertiseCard
                    title="Rental Determination"
                    description={ rental_description }
                    image={ rental_image }
                />
                <ExpertiseCard
                    title="Insurance & property surveys"
                    description={ insurance_description }
                    image={ insurance_image }
                />
                <ExpertiseCard
                    title="Development Feasibility studies"
                    description={ development_description }
                    image={ development_image }
                />
              </div>
            </div>
            <div css={ row_css }>
              <div css={ expertise_grid_container }>
                <ExpertiseCard
                    title="Agricultural smallholdings"
                    description={ agricultural_description }
                    image={ agricultural_image }
                />
                <ExpertiseCard
                    title="Servitudes"
                    description={ servitude_description }
                    image={ servitude_image }
                />
                <ExpertiseCard
                    title="Antennae & signage"
                    description={ antennae_description }
                    image={ antennae_image }
                />
              </div>
            </div>
            <div css={ row_css }>
              <div css={ expertise_grid_container }>
                <ExpertiseCard
                    title="Land restitution"
                    description={ restitution_description }
                    image={ restitution_image }
                />
                <ExpertiseCard
                    title="Specialised properties"
                    description={ specialised_description }
                    image={ specialised_image }
                />
                <ExpertiseCard
                    title="Municipal rates objections"
                    description={ rates_description }
                    image={ rates_image }
                />
              </div>
            </div>
          </div>
          <ExpertiseGuarantee />
          <ExpertisePanels />
          <BusinessAffiliates
              heading="OUR CLIENTS INCLUDE:"
              heading_style="expertisepage"
          />
        </div>
    )
}

const main_css = css`
    min-width: 100%;
    min-height: 28.125rem;
`

const expertise_container = css`
    display: grid;
`

const row_css = css`
    display: flex;
    flex-direction: row;
`

const expertise_grid_container = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1.5rem;
    margin-right: auto;
    margin-left: auto;
`
export default Expertise
