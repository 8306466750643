/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

//import { useTheme } from '../hooks'

function MainNavbar(props) {
    const { children } = props

    //const theme = useTheme()

    return (
        <div css={ main_css("white") }>
          { children }
        </div>
    )
}

const main_css = (background) => css`
    display: inline-block;
    background-color: ${background};
    height: 3.5vw;
    width: 80vw;
    padding-top: 1vw;
`

export default MainNavbar
