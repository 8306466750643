import React from 'react'
import { Routes, Route } from "react-router-dom"

import './App.css';

import MainLayout from './components/MainLayout'
import Home from './components/Home'
import Expertise from './components/Expertise'
import About from './components/About'
import News from './components/News'
import Contact from './components/Contact'
import Terms from './components/Terms'

export default function AppRoutes() {
    return (
        <Routes>
          <Route path="/*" element={ <MainLayout /> }>
            <Route path="" element={ <Home /> } />
            <Route path="expertise" element={ <Expertise /> } />
            <Route path="about" element={ <About /> } />
            <Route path="news" element={ <News /> } />
            <Route path="contact" element={ <Contact /> } />
            <Route path="terms" element={ <Terms /> } />
          </Route>
        </Routes>
    );
}

