/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { PageCoverHeader } from './PageCoverHeader'
import { OurPurpose } from './OurPurpose'
import { Valuers } from './Valuers'

function About(props) {
    return (
        <div css={ main_css }>
          <PageCoverHeader
              page="about"
              title="About Us"
          />
          <OurPurpose />
          <Valuers />
        </div>
    )
}

const main_css = css`
    min-width: 100%;
    min-height: 22.5vw;
`

export default About
