/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import guarantee_image from '../images/guarantee-image.jpg'

export default function ExpertiseGuarantee(props) {
    return (
        <div>
          <div css={ main_css }>
            <div css={ heading }>WE GUARANTEE</div>
            <div css={ row_css }>
              <div css={ grid_container }>
                <div css={ left_pane }></div>
                <div css={ right_pane }>
                  <div css={ specific_row_css }>
                    <div css={ icon_wrapper_css }>
                      <i className="material-icons" css={ icon_css }>person</i>
                    </div>
                    <div css={ text_section }>
                      <div css={ text_heading }>INDEPENDENCE</div>
                      <div css={ text_content }>
                        We provide valuations without favour, based on market evidence and we are immune to pressure and influence that may be exerted from time to time by other interested parties.
                      </div>
                    </div>
                  </div>
                  <div css={ specific_row_css }>
                    <div css={ icon_wrapper_css }>
                      <i className="material-icons" css={ icon_css }>thumb_up</i>
                    </div>
                    <div css={ text_section }>
                      <div css={ text_heading }>GREAT SERVICE</div>
                      <div css={ text_content }>
                        We appreciate the often urgent nature of the valuation industry and our team works fast and efficiently, often after hours, to help our clients meet their deadlines.
                      </div>
                    </div>
                  </div>
                  <div css={ specific_row_css }>
                    <div css={ icon_wrapper_css }>
                      <i className="material-icons" css={ icon_css }>lock</i>
                    </div>
                    <div css={ text_section }>
                      <div css={ text_heading }>CONFIDENTIALITY</div>
                      <div css={ text_content }>
                        We understand the highly confidential nature of the work that we do and conduct ourselves in a discreet and courteous manner in all our dealings.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

const heading = css`
    margin-left: auto;
    margin-right: auto;
    font-size: 1.9vw;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    padding: 1.6vw;
`

const main_css = css`
    display: grid;
    max-width: 70%;
    min-height: 33.6vw;
    margin-left: auto;
    margin-right: auto;
`

const row_css = css`
    display: flex;
    flex-direction: row;
`

const grid_container = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const left_pane = css`
    width: 26.75vw;
    height: 33.6vw;
    background-size: 27vw;
    background-image: url(${guarantee_image});
    background-repeat: no-repeat;
`

const right_pane = css`
    width: 24vw;
    height: 33.6vw;
    padding-left: 2.4vw;
`

const icon_wrapper_css = css`
    display: inline-block;
    width: 5.6vw;
    height: 5.6vw;
    border: 0.3vw solid #e5b034;
    background-repeat: no-repeat;
    color: #e5b034;
`

const icon_css = (icon) => css`
    color: #e5b034;
    height: 2.5rem;
    width: 2.5rem;
    padding: 1.8vw;
    font-size: 2vw;
`

const text_section = css`
    display: inline-block;
    padding-left: 1.2vw;
    width: 16.5vw;
    line-height: 1.7;
    text-rendering: optimizeLegibility;
    letter-spacing: -0.3px;
`

const text_heading = css`
    font-size: 1.2vw;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    color: #222329;
    padding-bottom: 0.4vw;
`

const text_content = css`
    color: #222329;
    font-size: 0.75vw;
    font-weight: 300;
    font-family: "Roboto", Sans-serif;
`

const specific_row_css = css`
    min-height: 10.5vw;
    padding-bottom: 1.6vw;
`
