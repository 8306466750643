/** @jsxImportSource @emotion/react */
import { useEffect, useState, useRef, Fragment } from 'react'
import { css } from '@emotion/react'

import { PageCoverHeader } from './PageCoverHeader'

function News(props) {
    return (
        <div css={ main_css }>
          <PageCoverHeader
              page="news"
              title="News"
          />
        </div>
    )
}

const main_css = css`
    border: solid pink 1px;
    min-width: 100%;

    min-height: 450px;
`

export default News
