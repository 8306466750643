/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

export function UIButton(props) {
    const { label, color, width, height, background_color, onClickFn } = props

    return (
        <button
            css= { button_css(color, width, height, background_color ) }
            onClick={ onClickFn }
        >
          { label }
        </button>
    )
}

const button_css = (color, width, height, background_color) => css`
    border: none;
    border-radius: 0.2vw;
    cursor: pointer;
    color: ${color};
    width: ${width};
    height: ${height};
    font-size: 0.8vw;
    background-color: ${background_color};
`
