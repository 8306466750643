/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { TeamMember } from './TeamMember'

import colin_image from '../images/Colin-edit.jpg'
import cherry_image from '../images/cherry.jpg'
import paul_image from '../images/Paul-edit.jpg'
import liz_image from '../images/liz.jpg'
import kate_image from '../images/kate.jpg'
import bev_image from '../images/Bev.jpg'
import shaun_image from '../images/shaun_photo.jpg'

export function Valuers(props) {
    return (
        <div css={ content_wrapper }>
          <h2 css={ content_heading }>Valuers</h2>
          <div css={ content_text }>
            <p>Colin, and his wife Cherry, started their own independent valuation business in May 1997. As the business grew over the years, a solid team of first rate people have been gathered and nurtured to become the backbone of this small to medium business enterprise.</p>
          </div>
          <div css={ valuers_wrapper }>
            <div css={ row_css }>
              <div css={ valuer_grid_container }>
                <div css={ valuer_card_css }>
                  <TeamMember
                      name="Colin Douglas"
                      image={colin_image}
                      qualifications="(Principal Member) Professional Valuer; Appraiser; (SA Council of Valuers Membership No. 3019); Member SA Institute of Valuers; Associate Member of the The Association of South African Quantity Surveyors ; Construction Supervisor Diploma; National Diploma Property Valuation; B.Comm (UNISA)."
                      extra_info={["After matriculating from Kingswood College in Grahamstown in 1977, Colin completed a three-year Construction Supervisor’s Diploma and then worked in the construction industry, which gave him a sound property background. In 1985 he joined the United Building Society (now ABSA) Valuations Department. He started with residential valuations and then specialised in commercial valuations and township developments. During his years with ABSA he acquired his National Diploma in Property Valuations as well as a B.Comm degree.", "In May 1997, after 12 years of property valuation experience with a leading financial institution, he and his wife Cherry started Douglas Property Valuations CC and developed a strong team of dependable valuers, all of whom are registered with the SA Council of Valuers as well as the SA Institute of Valuers. His knowledge of property valuations, particularly commercial and industrial property, is extensive and he undertakes the more challenging and specialised valuation projects that come our way including township developments, hotels, etc."]}
                  />
                </div>
                <div css={ valuer_card_css }>
                  <TeamMember
                      name="Cherry Douglas"
                      image={cherry_image}
                      qualifications="(Member) Professional Valuer; (SA Council of Valuers Membership No. 4814); Appraiser; Member SA Institute of Valuers; National Diploma Property Valuation; B.A (UCT)."
                      extra_info={["Cherry was born and educated in Zimbabwe and attended UCT where she graduated with a BA and then a post graduate diploma in Education.  Later she trained and qualified as a valuer and currently undertakes mainly commercial valuations as well as heading up the administrative side of the business.  She also relishes undertaking certain specialised valuations including, for example, disputes over views, etc."]}
                  />
                </div>
                <div css={ valuer_card_css }>
                  <TeamMember
                      name="Paul Bowen-Davies"
                      image={paul_image}
                      qualifications="Professional Valuer (SA Council of Valuers Membership No. 5262)"
                      extra_info={["Before joining us in October 2001, Paul was the Marketing Manager for an agricultural organisation in Zimbabwe and a light aircraft pilot. He is now a fully qualified Professional Valuer who undertakes a broad range of commercial and residential properties with extensive experience throughout the South-Western Cape in both market and insurance valuations for banks and private clients."]}
                  />
                </div>
              </div>
            </div>
            <div css={ row_css }>
              <div css={ valuer_grid_container }>
                <div css={ valuer_card_css }>
                  <TeamMember
                      name="Liz Moran"
                      image={liz_image}
                      qualifications="Candidate Valuer (SA Council of Valuers Membership No.7822 );   N.D. Real Estate (CPUT)"
                      extra_info={["Liz joined our team in 2005 as a PA to Colin after working in a similar position at another valuation firm. Over the years she has absorbed extensive valuation knowledge by working with one of Cape Town’s top valuers and now commands an extensive understanding of the valuation field. She has recently finished her formal studies towards a National Diploma in Real Estate (Valuations), which in conjunction with her background and attention to detail, positions her as an outstanding valuer. She undertakes mostly commercial valuation work."]}
                  />
                </div>
                <div css={ valuer_card_css }>
                  <TeamMember
                      name="Kate Hurt"
                      image={kate_image}
                      qualifications="Candidate Valuer; (SA Council of Valuers Membership No. 8013); Member SA Institute of Valuers; MSc Property Studies (UCT), Bachelor of Journalism (Rhodes), PGDip. Media Management (Rhodes)"
                      extra_info={["Kate comes from a background in journalism, reporting specifically on business in Africa and travelling across countries in East, West, South and Central Africa. She joined the firm in 2017 and is currently a Candidate Valuer completing her Masters in Property Studies at University of Cape Town. She also oversees many of the administrative day-to-day tasks at the firm."]}
                  />
                </div>
              </div>
            </div>
          </div>
          <h2 css={ content_heading }>Office support team</h2>
          <div css={ content_text }>
            <p>The valuers are assisted by the researchers and office support team. Together, they ensure the smooth and efficient running of our office. They are constantly researching, capturing, typing, communicating and generally giving our professional team a first-class backup service.</p>
            <p>We depend on these valuable ladies and gentlemen – Bev and Shaun – as, often, our clients’ first contact with our firm is through them.</p>
          </div>
          <div css={ valuers_wrapper }>
            <div css={ row_css }>
              <div css={ valuer_grid_container }>
                <div css={ valuer_card_css }>
                  <TeamMember
                      name="Bev O'Kelly"
                      image={bev_image}
                  />
                </div>
                <div css={ valuer_card_css }>
                  <TeamMember
                      name="Shaun Douglas"
                      image={shaun_image}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

const content_wrapper = css`
    width: 100%;
    min-height: 15vw;
    color: #7a7a7a;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    text-align: center;
    padding-bottom: 2.4vw;
`

const content_heading = css`
    font-weight: 600;
    line-height: 1;
    font-size: 2.25vw;
    color: #595858;
    font-family: "Playfair Display", serif;
    letter-spacing: 0.06vw;
`

const content_text = css`
    padding-left: 11.9vw;
    padding-right: 11.9vw;
    line-height: 1.8vw;
    font-size: 0.8vw;

    p {
    padding-bottom: 0.8vw;
    }
`

const valuers_wrapper = css`
    margin: auto;
    display: grid;
    max-width: 85%;
`

const row_css = css`
    display: flex;
    flex-direction: row;
`

const valuer_grid_container = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0.8vw;
    margin-right: auto;
    margin-left: auto;
`

const valuer_card_css = css`
    margin-left: 0.3vw;
    margin-right: 0.3vw;
`
