/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { TeamMember } from "./TeamMember";

import colin_image from "../images/Colin-edit.jpg";
import cherry_image from "../images/cherry.jpg";
import paul_image from "../images/Paul-edit.jpg";
import liz_image from "../images/liz.jpg";
import bev_image from "../images/Bev.jpg";
import kate_image from "../images/kate.jpg";
import shaun_image from "../images/shaun_photo.jpg";

export function TeamHome(props) {
  return (
    <div css={team_grid_wrapper}>
      <h2 css={section_heading}>The Douglas Property Team</h2>
      <div css={section_description}>
        <p>
          After working in the valuations industry for 12 years, Colin started
          his own independent valuation business in May 1997 with his wife
          Cherry as his assistant. As the business grew over the years, a solid
          team of first rate people have been gathered and nurtured to become
          the backbone of this small to medium business enterprise.
        </p>
      </div>
      <table css={table_css}>
        <tbody>
          <tr css={table_row}>
            <td css={table_cell}>
              <TeamMember
                name="Colin Douglas"
                image={colin_image}
                qualifications="(Principal Member) Professional Valuer; Appraiser; (SA Council of Valuers Membership No. 3019); Member SA Institute of Valuers; Associate Member of the The Association of South African Quantity Surveyors ; Construction Supervisor Diploma; National Diploma Property Valuation; B.Comm (UNISA)."
                extra_info={[
                  "After matriculating from Kingswood College in Grahamstown in 1977, Colin completed a three-year Construction Supervisor’s Diploma and then worked in the construction industry, which gave him a sound property background. In 1985 he joined the United Building Society (now ABSA) Valuations Department. He started with residential valuations and then specialised in commercial valuations and township developments. During his years with ABSA he acquired his National Diploma in Property Valuations as well as a B.Comm degree.",
                  "In May 1997, after 12 years of property valuation experience with a leading financial institution, he and his wife Cherry started Douglas Property Valuations CC and developed a strong team of dependable valuers, all of whom are registered with the SA Council of Valuers as well as the SA Institute of Valuers. His knowledge of property valuations, particularly commercial and industrial property, is extensive and he undertakes the more challenging and specialised valuation projects that come our way including township developments, hotels, etc.",
                ]}
              />
            </td>
            <td css={table_cell}>
              <TeamMember
                name="Cherry Douglas"
                image={cherry_image}
                qualifications="(Member) Professional Valuer; (SA Council of Valuers Membership No. 4814); Appraiser; Member SA Institute of Valuers; National Diploma Property Valuation; B.A (UCT)."
                extra_info={[
                  "Cherry was born and educated in Zimbabwe and attended UCT where she graduated with a BA and then a post graduate diploma in Education.  Later she trained and qualified as a valuer and currently undertakes mainly commercial valuations as well as heading up the administrative side of the business.  She also relishes undertaking certain specialised valuations including, for example, disputes over views, etc.",
                ]}
              />
            </td>
            <td css={table_cell}>
              <TeamMember
                name="Paul Bowen-Davies"
                qualifications="Professional Valuer (SA Council of Valuers Membership No. 5262)"
                extra_info={[
                  "Before joining us in October 2001, Paul was the Marketing Manager for an agricultural organisation in Zimbabwe and a light aircraft pilot. He is now a fully qualified Professional Valuer who undertakes a broad range of commercial and residential properties with extensive experience throughout the South-Western Cape in both market and insurance valuations for banks and private clients.",
                ]}
                image={paul_image}
              />
            </td>
          </tr>
          <tr css={table_row}>
            <td css={table_cell}>
              <TeamMember
                name="Kate Hurt"
                qualifications="Candidate Valuer; (SA Council of Valuers Membership No. 8013); Member SA Institute of Valuers; MSc Property Studies (UCT), Bachelor of Journalism (Rhodes), PGDip. Media Management (Rhodes)"
                extra_info={[
                  "Kate comes from a background in journalism, where she spent many years reporting on business and economics in African markets. She joined the firm in 2017 and has completed a Masters in Property Studies at UCT. Her journalistic skills and experience have assisted her in becoming a meticulous and objective valuer.",
                ]}
                image={kate_image}
              />
            </td>
            <td css={table_cell}>
              <TeamMember
                name="Liz Moran"
                qualifications="Professional Associated Valuer (SA Council of Valuers Membership No.7822 );   N.D. Real Estate (CPUT)"
                extra_info={[
                  "Liz joined our team in 2005 as a PA to Colin after working in a similar position at another valuation firm. Over the years she has absorbed extensive valuation knowledge by working with one of Cape Town’s top valuers and now commands an extensive understanding of the valuation field. She has recently finished her formal studies towards a National Diploma in Real Estate (Valuations), which in conjunction with her background and attention to detail, positions her as an outstanding valuer. She undertakes mostly commercial valuation work.",
                ]}
                image={liz_image}
              />
            </td>
            <td css={table_cell}>
              <TeamMember name="Bev O'Kelly" image={bev_image} />
            </td>
          </tr>
          <tr css={table_row}>
            <td css={table_cell}></td>
            <td css={table_cell}>
              <TeamMember name="Shaun Douglas" image={shaun_image} />
            </td>
            <td css={table_cell}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

const team_grid_wrapper = css`
  padding-bottom: 2.4vw;
  padding-left: 4.9vw;
  padding-right: 4.9vw;
`;

const section_heading = css`
  font-family: "Play-fair Display", serif;
  font-size: 2.25vw;
  letter-spacing: 0.06vw;
  color: #595858;
  text-align: center;
  font-weight: 600;
`;

const section_description = css`
  padding-left: 6.4vw;
  padding-right: 6.4vw;
  color: #595858;
  font-family: "Roboto", sans-serif;
  font-size: 0.8vw;
  font-weight: 100;
  text-align: center;
  line-height: 2;
`;

const table_css = css``;

const table_row = css``;

const table_cell = css`
  height: 16vw;
  width: 10vw;
  padding-left: 1.6vw;
  padding-right: 1.6vw;
`;
