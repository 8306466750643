/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

export function PageDescription(props) {
    const { heading, subheading, text } = props
    
    return (
        <div css={ content_wrapper }>
          <h2 css={ content_heading }>{heading}</h2>
          <h4 css={ content_subheading }>{subheading}</h4>
          <div css={ content_text }>
            <p>{text}</p>
          </div>
        </div>
    )
}

const content_wrapper = css`
    width: 100%;
    min-height: 15vw;
    color: #7a7a7a;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    text-align: center;
    padding-top: 1.2vw;
    padding-bottom: 2.4vw;
`

const content_heading = css`
    font-weight: 600;
    line-height: 1;
    font-size: 2.5vw;
    color: #595858;
    font-family: "Playfair Display", serif;
    letter-spacing: 0.06vw;
`

const content_subheading = css`
    font-weight: 500;
    line-height: 1;
    font-size: 1.6vw;
    color: #595858;
    font-family: "Playfair Display", sans-serif;
    letter-spacing: 0.06vw;
`

const content_text = css`
    padding-left: 11.9vw;
    padding-right: 11.9vw;
    line-height: 1.7vw;
    font-size: 0.8vw;

    p {
    padding-bottom: 0.8vw;
    }
`
