/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useNavigate } from "react-router-dom"

import { UIButton } from '../ui/UIButton'

export function AboutShort(props) {
    const navigate = useNavigate()

    function onClickFn() {
        navigate("/about")
    }

    return (
        <div css={ content_wrapper }>
          <h2 css={ content_heading }>Who we are</h2>
          <div css={ content_text }>
            <p>Our mission is to provide valuations that are credible and well-motivated so as to exceed the expectation of our clients and that of the industry. Our founders have had over 35 years’ experience across a wide range of property valuations, and have developed long-term relationships with clients who appreciate our above-average diligence taken in our work. Today we are on the Panel of Valuers for some of South Africa’s top banks, including Nedbank, Investec and SA Home Loans.</p>
            <p>Our valuations are well-researched, strongly-motivated and easy to understand. </p>
            <p>All our valuers are members of both the <a href="https://www.saiv.org.za/" target="_blank" rel="noreferrer" css={ text_link }>South African Institute of Valuers</a> and the <a href="https://www.sacpvp.co.za/" target="_blank" rel="noreferrer" css={ text_link }>South African Council for the Property Valuers Profession</a></p>
          </div>
          <div css={ button_section }>
            <UIButton
                color="white"
                width="6vw"
                height="2.5vw"
                background_color="rgba(0,0,0,0.59)"
                label="Read more"
                onClickFn={ onClickFn }
            />
          </div>
        </div>
    )
}

const content_wrapper = css`
    width: 100%;
    min-height: 15vw;

    color: #7a7a7a;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    text-align: center;
    padding-top: 1.2vw;
    padding-bottom: 3vw;
`

const content_heading = css`
    font-weight: 600;
    line-height: 1;
    font-size: 3vw;
    color: #595858;
    font-family: "Playfair Display", serif;
    letter-spacing: 0.05vw;
`

const content_text = css`
    padding-left: 10vw;
    padding-right: 10vw;
    line-height: 1.7em;
    font-size: 0.82vw;

    p {
    padding-bottom: 0.82vw;
    }
`

const text_link = css`
    
    color: #252525;
    text-decoration: underline;
`

const button_section = css`
    display: block;
    width: 100%;
    min-height: 2vw;
`
