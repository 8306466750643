/** @jsxImportSource @emotion/react */

import { Fragment } from 'react'
//import { DateTime } from 'luxon'

//import { useTheme, useAppContext } from '../hooks'

export function UIGlobalLayout(props) {
    const { children } = props

    //const theme = useTheme()
//    const timeout_ref = useRef()
    //const { state } = useAppContext()

    /* const global_styles = css`
     *     body {
     *     background-color: ${theme.main_background};
     *     color: ${theme.primary_text};
     *     }

     *     a {
     *     color: ${theme.primary_text};
     *     }
     * ` */

    return (
        <Fragment>
          { children }
        </Fragment>
    )
}

