/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import { PageCoverHeader } from './PageCoverHeader'
import { PageDescription } from './PageDescription'
import { ContactOption } from './ContactOption'

function Contact(props) {
    return (
        <div css={ main_css }>
          <PageCoverHeader
              page="contact"
              title="Contact Us"
          />
          <PageDescription
              heading="Need a valuations expert?"
              subheading="CONTACT US FOR A QUOTE"
              text="Douglas Property Valuations will provide you with valuation reports that are credible and well-motivated, exceeding the expectations of our clients and those of the industry."
          />
          <div css={ contact_options_section }>
            <table css={ table_css }>
              <tbody>
              <tr>
                <td css={ contact_option_container }>
                  <ContactOption
                      label="CALL US"
                      icon={ faPhone }
                      description="Give us a ring and we will be happy to help you with any query"
                      contact_options={["+27 (0)65 511 3377 (Shaun)", "+27 (0)83 456 1419 (Kate)"]}
                  />
                </td>
                <td css={ contact_option_container }>
                  <ContactOption
                      label="EMAIL US"
                      icon={ faEnvelope }
                      description="Drop us a mail and we will get right back you"
                      contact_options={["vals@douglasproperty.co.za", "", ""]}
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
    )
}

const main_css = css`
    min-width: 100%;
    min-height: 22.5vw;
`

const contact_options_section = css`
    width: 65%;
    min-height: 22.5vw;
    margin-left: auto;
    margin-right: auto;
`

const table_css = css`
    border-collapse: collapse;
`

const contact_option_container = css`
    width: 50%;
    min-height: 22.5vw;
`

export default Contact
