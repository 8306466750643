/** @jsxImportSource @emotion/react */
import { useEffect, useState, useRef, Fragment } from 'react'
import { css } from '@emotion/react'

import { PageCoverHeader } from './PageCoverHeader'

function Terms(props) {
    return (
        <div css={ main_css }>
          <PageCoverHeader
              page="terms"
              title="Terms and Conditions"
          />
          <div css={ terms_content }>
            <p>Whilst we have our clients’ best interests at heart and in our minds at all times, we must clarify the following:</p>
            <ul>
              <li>We endeavour to ascertain the correct values using the most appropriate methods applicable to the tasks at hand.</li>
              <li>We guarantee to be thorough and comprehensive in all research and reporting, and to take no short-cuts or make unreasonable assumptions.</li>
              <li>We cannot guarantee that the valuations that we derive will always match those that our clients are hoping for.</li>
              <li>Our reports are well-motivated and our aim is always that the reader should be able to follow the logic and come to similar conclusions.</li>
              <li>Our fees are based on the amount of work and expertise needed/applied to attain the most accurate valuation possible. Fees are payable in full regardless of whether or not the valuation figures derived match what our clients hoped for.</li>
            </ul>
          </div>
        </div>
    )
}

const main_css = css`
    min-width: 100%;
    min-height: 450px;
`

const terms_content = css`
    color: #595858;
    font-family: "Roboto", Sans-serif;
    font-size: 16px;
    font-weight: 300;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 56px;
    padding-bottom: 56px;
    line-height: 1.7;
    li {
    padding-top: 16px;
    }
`

export default Terms
