/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { PageCoverHeader } from './PageCoverHeader'
import { AboutShort } from './AboutShort'
import { ExpertiseShort } from './ExpertiseShort'
import { TeamHome } from './TeamHome'
import { BusinessAffiliates } from './BusinessAffiliates'

function Home(props) {
    return (
        <div css={ main_css }>
          <PageCoverHeader
              page="home"
              title="Providing credible property valuations"
          />
          <AboutShort />
          <ExpertiseShort />
          <TeamHome />
          <BusinessAffiliates
              heading="Businesses we work with"
              heading_style="homepage"
              />
        </div>
    )
}

const main_css = css`
    min-width: 100%;
    min-height: 22.5vw;
`

export default Home
