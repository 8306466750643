/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import nedbank_logo from '../images/Nedbank-logo.jpg'
import investec_logo from '../images/investec-logo.png'
import sahl_logo from '../images/SA-home-loans-logo.jpg'
import nedbank_private_logo from '../images/nedbank-private-wealth-logo.jpg'

export function ExpertisePanels(props) {
    return (
        <div css={ main_css }>
          <h2 css={ section_heading }>WE'RE ON THE PANEL OF VALUERS FOR:</h2>
          <div css={ icon_list }>
            <div css={icon_css(nedbank_logo)}></div>
            <div css={icon_css(sahl_logo)}></div>
            <div css={icon_css(investec_logo)}></div>
            <div css={icon_css(nedbank_private_logo)}></div>
          </div>
        </div>
    )
}

const main_css = css`
    display: grid;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1.6vw;
    padding-bottom: 1.6vw;
`

const section_heading = css`
    font-family: "Roboto", sans-serif;
    font-size: 1.75vw;
    letter-spacing: 0.1vw;
    color: #222329;
    text-align: center;
    font-weight: 900;
`

const icon_list = css`
    display: flex;
`

const icon_css = (image) => css`
    display: inline-block;
    height: 7vw;
    width: 7vw;
    margin-left: auto;
    margin-right: auto;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-size: 7vw;
`
