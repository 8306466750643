/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { map } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function ContactOption(props) {
    const { icon, label, description, contact_options } = props

    return (
        <div css={ content_wrapper }>
          <FontAwesomeIcon css={ contact_icon } icon={ icon } />
          <div css={ contact_label }>{ label }</div>
          <div css={ contact_description }>{ description }</div>
          { map(contact_options, (option) => {
                return (
                    <div css={ contact_option }>{ option }</div>
                )
          }) }
        </div>
    )
}

const content_wrapper = css`
    height: 100%;
    padding-top: 2.4vw;
    padding-bottom: 2.4vw;
    text-align: center;
    padding-left: 1.3vw;
    padding-right: 1.3vw;
`

const contact_icon = (icon) => css`
    height: 3vw;
    width: 3vw;
    background-image: url(${icon});
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
    color: #fd5e29;
`

const contact_label = css`
    color: #777777;
    font-size: 1.5vw;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.4vw;
    font-family: "Roboto", sans-serif;
    padding-bottom: 0.8vw;
    padding-top: 1.6vw;
`

const contact_description = css`
    color: #b5b5b5;
    width: 100%;
    font-size: 0.7vw;
    line-height: 1.5vw;
    font-family: "Roboto", sans-serif;
    padding-top: 0.4vw;
    padding-bottom: 0.4vw;
`

const contact_option = css`
    color: #fd5e29;
    font-size: 1.2vw;
    font-weight: bold;
    font-family: "Roboto", Sans-serif;
    padding-top: 0.8vw;
`
