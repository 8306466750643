/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Outlet, useNavigate } from "react-router-dom"

import MainNavbar from './MainNavbar'
import Footer from './Footer'

import company_logo from '../images/cropped-cropped-DPV-logo-writing.png'

function MainLayout() {
    const navigate = useNavigate()
    
    return (
        <div css={ main_css }>
          <MainNavbar>
            {/* <div css={ header_css }>
                Main Navbar
                </div> */}
            <div css={ header_icon_css }>
              <img css={ header_image } src={ company_logo } alt="dpv_logo" />
            </div>
            <div css={ nav_section_links }>
              {/* <div css={ nav_section_link } onClick={() => navigate("/news") }>
                  News
                  </div> */}
              <div css={ nav_section_link } onClick={() => navigate("/terms") }>
                T&Cs
              </div>
              <div css={ nav_section_link } onClick={() => navigate("/contact") }>
                Contact us
              </div>
              <div css={ nav_section_link } onClick={() => navigate("/about") }>
                About us
              </div>
              <div css={ nav_section_link } onClick={() => navigate("/expertise") }>
                Expertise
              </div>
              <div css={ nav_section_link } onClick={() => navigate("/") }>
                Home
              </div>
            </div>
          </MainNavbar>

          <div css={ content_css }>
            <Outlet />
          </div>
          <div css={ footer_wrapper }>
            <Footer />
          </div>
        </div>
    )
}

const main_css = css`
    display: inline-block;
    width: 80vw;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 3vh;
    border-top: 1px solid #d2d2d4
`

const content_css = css`
    display: inline-block;
    width: 80vw;
`

const header_icon_css = css`
    display: inline-block;
    width: 25%;
    padding-left: 1.2vw;
`

const header_image = css`
    width: 100%;
`

const nav_section_links = css`
    display: inline-block;
    float: right;
    width: 45vw;
    min-height: 2.8vw;
`

const nav_section_link = css`
    float: right;
    display: inline-block;
    color: black;
    font-size: 0.82vw;
    width: 15%;
    text-align: center;
    min-height: 2.8vw;
    padding-top: 0.8vw;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-decoration: none;
    &:hover {
    cursor: pointer;
    }
`

const footer_wrapper = css`
    display: block;
    width: 80vw;
    min-height: 16.4vw;
    background-color: rgba(0, 0, 0, 0.5);
`

export default MainLayout
